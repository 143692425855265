<template>
  <div v-if="form">
    <div class="min-vh-100">
      <CRow class="no-gutters px-3 px-sm-0 mb-3">
        <b-col sm="6" class="text-center text-sm-left">
          <h1 class="mr-sm-4 header-main text-uppercase">รายละเอียดลูกค้า</h1>
          <!-- <b-button v-b-modal.modal-1>Launch demo modal</b-button> -->
        </b-col>
        <!-- <b-col sm="6" class="text-center text-sm-right">
          <span class="label-text mr-3 txt-relative">ระงับบัญชี</span>
          <b-form-checkbox
            switch
            size="lg"
            class="d-inline-block mb-1"
            @change="onChangeEnableMember"
            :value="true"
            :unchecked-value="false"
            v-model="form.user.enabled"
            ><span class="ml-2 main-label">{{
              form.user.enabled ? "ใช้งานปกติ" : "ระงับบัญชี"
            }}</span></b-form-checkbox
          >
        </b-col> -->
      </CRow>

      <div>
        <b-row class="pb-sm-1 position-relative mx-3 mx-sm-0 mb-3">
          <b-col sm="12" class="bg-white py-3">
            <b-row>
              <b-col cols="4" class="text-center">
                <div>
                  <div
                    v-bind:style="{
                      'background-image': 'url(' + form.user.imageUrl + ')',
                    }"
                    class="preview-box border-0 mb-2 affiliate-img"
                  ></div>
                  <p class="f-10 m-0 mt-2">
                    เป็นสมาชิกวันที่
                    {{ new Date(form.user.createdTime) | moment($formatDate) }}
                  </p>
                </div>
              </b-col>
              <b-col cols="8">
                <b-row class="align-items-end">
                  <b-col sm="6">
                    <InputSelect
                      title="คำนำหน้า"
                      name="prefixId"
                      isRequired
                      v-bind:options="list.prefix"
                      valueField="id"
                      textField="name"
                      v-model="form.user.prefixId"
                      @onDataChange="handleChangeTitle"
                      :isValidate="$v.form.user.prefixId.$error"
                      :v="$v.form.user.prefixId"
                    />
                  </b-col>
                  <b-col sm="6">
                    <InputText
                      textFloat="คำนำหน้า"
                      placeholder="คำนำหน้า"
                      type="text"
                      name="prefixDetail"
                      v-model="form.user.prefixDetail"
                      isRequired
                      :isValidate="$v.form.user.prefixDetail.$error"
                      :v="$v.form.user.prefixDetail"
                      :disabled="form.user.prefixId != 4"
                    />
                  </b-col>
                  <b-col sm="6">
                    <InputText
                      textFloat="ชื่อ"
                      placeholder="ชื่อ"
                      type="text"
                      name="firstname"
                      v-model="form.user.firstname"
                      isRequired
                      :isValidate="$v.form.user.firstname.$error"
                      :v="$v.form.user.firstname"
                    />
                  </b-col>
                  <b-col sm="6">
                    <InputText
                      textFloat="นามสกุล"
                      placeholder="นามสกุล"
                      type="text"
                      name="lastname"
                      v-model="form.user.lastname"
                      isRequired
                      :isValidate="$v.form.user.lastname.$error"
                      :v="$v.form.user.lastname"
                    />
                  </b-col>
                  <b-col sm="6">
                    <InputText
                      textFloat="เลขที่บัตรประชาชน"
                      placeholder="เลขที่บัตรประชาชน"
                      type="text"
                      name="cardId"
                      v-model="form.user.cardId"
                      isRequired
                      :isValidate="$v.form.user.cardId.$error"
                      :v="$v.form.user.cardId"
                      :maxLength="13"
                    />
                  </b-col>
                  <b-col sm="6">
                    <DateTimePicker
                      textFloat="วันเกิด"
                      type="date"
                      placeholder="กรุณาเลือกวันที่"
                      name="birthday"
                      :value="form.user.birthday"
                      isRequired
                      :isValidate="$v.form.user.birthday.$error"
                      :v="$v.form.user.birthday"
                      @input="onChangeBirthday"
                    />
                  </b-col>
                  <b-col sm="6">
                    <InputText
                      textFloat="อีเมล"
                      placeholder="อีเมล"
                      type="email"
                      name="email"
                      v-model="form.user.email"
                      isRequired
                      :isValidate="$v.form.user.email.$error"
                      :v="$v.form.user.email"
                    />
                  </b-col>
                  <b-col sm="6">
                    <div class="position-relative">
                      <u class="add-phone-number" @click="addPhoneNumber"
                        >เบอร์ติดต่อสำรอง</u
                      >
                      <div class="row no-gutters">
                        <div class="col">
                          <InputTextTelephone
                            ref="InputTextTelephone"
                            v-model="form.user.telephone"
                            textFloat="เบอร์โทรศัพท์"
                            placeholder="เบอร์โทรศัพท์"
                            type="text"
                            :id="`numberPhone`"
                            size="md"
                            isRequired
                            :maxLength="10"
                            :isValidate="$v.form.user.telephone.$error"
                            :v="$v.form.user.telephone"
                            class="f-regular"
                            @onKeypress="isNumber"
                            :list="list.countryCode"
                            :valueDropdown="form.user.countryCodeId"
                            @onChangeDropdown="
                              (val) => onChangeCountryCodeId(val, -1)
                            "
                          />
                        </div>
                        <div :class="['col-4 ', 'pl-3']">
                          <div :class="['mt-ext h-100']">
                            <InputText
                              v-model="form.user.telephoneExtension"
                              placeholder="ต่อ"
                              type="text"
                              :id="`ext`"
                              size="md"
                              :isValidate="
                                $v.form.user.telephoneExtension.$error
                              "
                              :v="$v.form.user.telephoneExtension"
                              class="f-regular"
                            />
                          </div>
                        </div>
                        <!-- <div class="col-1 pl-2">
                          <div :class="['mt-ext h-100 text-center']">
                            <b-button
                              type="button"
                              variant="link"
                              class="icon-delete"
                              @click="deletePhoneNumber(form, -1)"
                            >
                              <font-awesome-icon icon="trash-alt" />
                            </b-button>
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </b-col>
                  <b-col
                    sm="6"
                    v-for="(telephone, i) in form.user.altNumbers"
                    :key="i"
                  >
                    <div class="position-relative">
                      <div class="row no-gutters">
                        <div class="col">
                          <InputTextTelephone
                            v-model="telephone.number"
                            :textFloat="`เบอร์ติดต่อสำรอง ${i + 1}`"
                            placeholder="เบอร์โทรศัพท์"
                            type="text"
                            :id="`numberPhone-${i}`"
                            size="md"
                            isRequired
                            :maxLength="10"
                            :isValidate="
                              $v.form.user.altNumbers.$each.$iter[i].number
                                .$error
                            "
                            :v="$v.form.user.altNumbers.$each.$iter[i].number"
                            class="f-regular"
                            @onKeypress="isNumber"
                            :list="list.countryCode"
                            :valueDropdown="telephone.countryCodeId"
                            @onChangeDropdown="
                              (val) => onChangeCountryCodeId(val, i)
                            "
                          />
                        </div>
                        <div :class="['col-3 ', 'pl-3']">
                          <div :class="['mt-ext h-100']">
                            <InputText
                              v-model="telephone.extensionNumber"
                              placeholder="ต่อ"
                              type="text"
                              :id="`ext-${i}`"
                              size="md"
                              :isValidate="
                                $v.form.user.altNumbers.$each.$iter[i]
                                  .extensionNumber.$error
                              "
                              :v="
                                $v.form.user.altNumbers.$each.$iter[i]
                                  .extensionNumber
                              "
                              class="f-regular"
                            />
                          </div>
                        </div>
                        <div class="col-1 pl-2">
                          <div :class="['mt-ext h-100 text-center']">
                            <b-button
                              type="button"
                              variant="link"
                              class="icon-delete"
                              @click="deletePhoneNumber(telephone, i)"
                            >
                              <font-awesome-icon icon="trash-alt" />
                            </b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-col>
                  <b-col sm="6">
                    <p
                      class="f-14 text-blue text-underline pointer"
                      @click="resetPassword"
                    >
                      รีเซ็ทรหัสผ่าน
                    </p>
                    <!-- <InputText
                    textFloat="เลขประจำตัวผู้เสียภาษี"
                    placeholder="เลขประจำตัวผู้เสียภาษี"
                    type="text"
                    name="taxId"
                    v-model="form.user.taxId"
                    isRequired
                    :isValidate="$v.form.user.taxId.$error"
                    :v="$v.form.user.taxId"
                  /> -->
                  </b-col>
                  <b-col sm="6">
                    <label class="font-weight-bold">ข้อกำหนดและเงื่อนไข</label>
                    <b-form-group class="m-auto">
                      <b-form-radio-group v-model="form.user.isConsent">
                        <b-form-radio :value="1">ยินยอม</b-form-radio>
                        <b-form-radio :value="0">ไม่ยินยอม</b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6">
                    <label class="font-weight-bold">PDPA</label>
                    <b-form-group class="m-auto">
                      <b-form-radio-group v-model="form.user.approveConsent">
                        <b-form-radio :value="1">ยินยอม</b-form-radio>
                        <b-form-radio :value="0">ไม่ยินยอม</b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="mt-5">
                  <b-col md="6"></b-col>
                  <b-col md="6" class="text-sm-right">
                    <button
                      type="button"
                      @click="submit"
                      :disabled="isDisable"
                      class="btn btn-main btn-details-set ml-md-2 text-uppercase"
                    >
                      บันทึก
                    </button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <!-- <b-col md="6">
            <div class="bg-white h-100 br-10">
              <div class="row h-100 p-3">
                <b-col class="m-auto">
                  <b-row>
                    <b-col cols="7">
                      <b-row class="">
                        <b-col cols="6" class="font-weight-bold"
                          >เลขที่บัญชี :</b-col
                        >
                        <b-col class="mb-2 mb-sm-0" cols="6">
                          <span v-if="form.bankAccount.accountNo != null">{{
                            form.bankAccount.accountNo
                          }}</span>
                          <span v-else>-</span>
                        </b-col>
                      </b-row>

                      <b-row class="">
                        <b-col cols="6" class="font-weight-bold"
                          >ธนาคาร :</b-col
                        >
                        <b-col class="mb-2 mb-sm-0" cols="6">
                          <span v-if="form.bankAccount.bankName != null">{{
                            form.bankAccount.bankName
                          }}</span>
                          <span v-else>-</span>
                        </b-col>
                      </b-row></b-col
                    >
                  </b-row>

                  <b-row>
                    <b-col>
                      <div
                        class="text-center text-primary text-underline f-12 mt-4"
                      >
                        <router-link :to="'/member/bankaccount/details/' + id">
                          <span class="mr-1">ดูเอกสารบัญชี</span>
                        </router-link>
                        <router-link :to="'/member/log/' + id">
                          <span class="mr-1">ดูรายละเอียดคะแนนย้อนหลัง</span>
                        </router-link>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </div>
            </div>
          </b-col> -->
        </b-row>
        <b-row class="my-3 mx-0">
          <b-col md="6" class="pl-md-0 pr-md-1 px-0 mb-2 mb-md-0">
            <div class="bg-white p-3 h-100">
              <b-row>
                <b-col cols="8">
                  <p class="font-weight-bold">ที่อยู่ในการจัดส่ง</p>
                </b-col>
                <b-col cols="4" class="text-right"> </b-col>
              </b-row>

              <template
                v-if="form.user.shippingAddress && form.user.shippingAddress.id"
              >
                <div class="address-box">
                  <p>
                    {{
                      form.user.shippingAddress.prefixId == 3
                        ? ""
                        : form.user.shippingAddress.prefixId != 4
                        ? form.user.shippingAddress.prefixName
                        : form.user.shippingAddress.prefixDetail
                    }}
                    {{ form.user.shippingAddress.firstname }}
                    {{ form.user.shippingAddress.lastname }}
                  </p>
                  <p class="mb-3">
                    {{ form.user.shippingAddress.address }}
                    {{ form.user.shippingAddress.building }}
                    {{ form.user.shippingAddress.alley }}
                    {{ form.user.shippingAddress.road }}
                    {{ form.user.shippingAddress.subdistrict }} 
                    {{ form.user.shippingAddress.district }} 
                    {{ form.user.shippingAddress.province }} 
                    {{ form.user.shippingAddress.zipCode }} 
                    {{ form.user.shippingAddress.country }}
                  </p>

                  <p>
                    <b>เบอร์โทร : </b>
                    {{ form.user.shippingAddress.countryCodeName || "" }}
                    {{ form.user.shippingAddress.telephone || "-" }}
                    <template
                      v-if="form.user.shippingAddress.telephoneExtension"
                    >
                      ต่อ {{ form.user.shippingAddress.telephoneExtension }}
                    </template>
                  </p>
                  <p
                    v-if="
                      form.user.shippingAddress.altNumbers &&
                      form.user.shippingAddress.altNumbers.length
                    "
                  >
                    <b>เบอร์โทรสำรอง : </b>
                    <span
                      v-for="(telephone, j) of form.user.shippingAddress
                        .altNumbers"
                      :key="'telephone-' + j"
                    >
                      {{ telephone.countryCodeName || "" }}
                      {{ telephone.number }}
                      <template v-if="telephone.extensionNumber">
                        ต่อ {{ telephone.extensionNumber }}
                      </template>
                      {{
                        j < form.user.shippingAddress.altNumbers.length - 1
                          ? ","
                          : ""
                      }}
                    </span>
                  </p>
                </div>
              </template>
              <template v-else>
                <div class="text-danger">ไม่มีที่อยู่ในการจัดส่ง</div>
              </template>
            </div>
          </b-col>
          <b-col md="6" class="pr-md-0 pl-md-1 px-0">
            <div class="bg-white p-3 h-100">
              <b-row>
                <b-col cols="8">
                  <p class="font-weight-bold">ที่อยู่ในการออกใบเสร็จ</p>
                </b-col>
                <b-col cols="4" class="text-right"> </b-col>
              </b-row>
              <template
                v-if="form.user.billingAddress && form.user.billingAddress.id"
              >
                <div class="address-box">
                  <p>
                    {{
                      form.user.billingAddress.prefixId == 3
                        ? ""
                        : form.user.billingAddress.prefixId != 4
                        ? form.user.billingAddress.prefixName
                        : form.user.billingAddress.prefixDetail
                    }}
                    {{ form.user.billingAddress.firstname }}
                    {{ form.user.billingAddress.lastname }}
                  </p>
                  <p>
                    {{ form.user.billingAddress.address }}
                    {{ form.user.billingAddress.building }}
                    {{ form.user.billingAddress.alley }}
                    {{ form.user.billingAddress.road }}
                    {{ form.user.billingAddress.subdistrict }} 
                    {{ form.user.billingAddress.district }} 
                    {{ form.user.billingAddress.province }} 
                    {{ form.user.billingAddress.zipCode }} 
                    {{ form.user.billingAddress.country }}
                  </p>

                  <div class="mt-3">
                    <div v-if="form.user.billingAddress.isNormalPerson === 0">
                      <p>
                        <b>ชื่อบริษัท :</b>
                        {{ form.user.billingAddress.businessName || "-" }}
                      </p>
                      <p class="mb-0">
                        <b>ประเภทสาขา :</b>
                        {{
                          form.user.billingAddress.isMainBranch
                            ? `สำนักงานใหญ่`
                            : `สาขาอื่นๆ`
                        }}
                      </p>
                      <template
                        v-if="form.user.billingAddress.isMainBranch == 0"
                      >
                        <p class="mb-0">
                          <b>เลขที่สาขา :</b>
                          {{ form.user.billingAddress.branchNo || "-" }}
                        </p>
                        <p class="mb-0">
                          <b>ชื่อสาขา :</b>
                          {{ form.user.billingAddress.branchName || "-" }}
                        </p>
                      </template>
                    </div>
                    <p>
                      <b>เลขประจำตัวผู้เสียภาษี :</b>
                      {{ form.user.billingAddress.taxInvoice || "-" }}
                    </p>

                    <p>
                      <b>อีเมลในการจัดส่งใบกำกับภาษีอิเล็กทรอนิกส์ :</b>
                      {{ form.user.billingAddress.taxInvoiceEmail || "-" }}
                    </p>
                  </div>
                  <p>
                    <b>เบอร์โทร : </b>
                    {{ form.user.billingAddress.countryCodeName || "" }}
                    {{ form.user.billingAddress.telephone || "-" }}
                    <template
                      v-if="form.user.billingAddress.telephoneExtension"
                    >
                      ต่อ {{ form.user.billingAddress.telephoneExtension }}
                    </template>
                  </p>
                  <p
                    v-if="
                      form.user.billingAddress.altNumbers &&
                      form.user.billingAddress.altNumbers.length
                    "
                  >
                    <b>เบอร์โทรสำรอง : </b>
                    <span
                      v-for="(telephone, j) of form.user.billingAddress
                        .altNumbers"
                      :key="'telephone-' + j"
                    >
                      {{ telephone.countryCodeName || "" }}
                      {{ telephone.number }}
                      <template v-if="telephone.extensionNumber">
                        ต่อ {{ telephone.extensionNumber }}
                      </template>
                      {{
                        j < form.user.billingAddress.altNumbers.length - 1
                          ? ","
                          : ""
                      }}
                    </span>
                  </p>
                </div>
              </template>
              <template v-else>
                <div class="text-danger">ไม่ขอรับใบกำกับภาษี</div>
              </template>
            </div>
          </b-col>
        </b-row>
        <!-- <b-row class="nomargin-mobile py-2">
          <b-col md="4" class="mb-2 mb-md-0"
            ><div class="bg-white text-center py-2 h-100 br-10">
              <font-awesome-icon icon="medal" class="mr-2 text-orange" />ระดับ
              <span class="text-orange font-weight-bold f-20 ml-3">{{
                form.user.tier
              }}</span>
            </div></b-col
          >
          <b-col md="4" class="mb-2 mb-md-0"
            ><div class="bg-white text-center py-2 h-100 br-10">
              <font-awesome-icon icon="star" class="text-orange" /> คะแนน
              <span class="f-20 font-weight-bold ml-3">{{
                form.user.point | numeral("0,0")
              }}</span>
            </div></b-col
          >
          <b-col md="4"
            ><div class="bg-white text-center py-2 h-100 br-10">
              <b-row class="mt-1">
                <b-col cols="5" class="font-weight-bold"
                  >วันที่เป็นสมาชิก</b-col
                >
                <b-col class="mb-2 mb-sm-0" cols="7">
                  {{ new Date(form.user.createdTime) | moment($formatDate) }}
                </b-col>
              </b-row>
            </div></b-col
          >
        </b-row> -->

        <b-row class="mt-3 nomargin-mobile">
          <b-col xl="6" class="mb-2 mb-xl-0">
            <div class="bg-blue p-3">
              <b-row>
                <b-col class="m-auto">
                  <p class="font-weight-bold m-0">ยอดซื้อทั้งหมด</p>
                </b-col>
                <p
                  class="mb-2 mb-sm-0 f-16 font-weight-bold text-white py-2 text-right mr-2"
                >
                  ฿ {{ sectionuser.totalSale | numeral("0,0.00") }}
                </p>
              </b-row>
            </div>
          </b-col>
          <!-- <b-col xl="4" class="mb-2 mb-xl-0">
            <div class="bg-blue p-3 h-100">
              <b-row class="h-100">
                <b-col class="m-auto">
                  <p class="font-weight-bold m-0">
                    คะแนนที่ได้รับ
                  </p>
                </b-col>
                <b-col
                  class="f-16 font-weight-bold text-white text-right m-auto"
                >
                  {{ sectionuser.pointWait | numeral("0,0") }}</b-col
                >
              </b-row>
            </div>
          </b-col> -->
          <b-col xl="6" class="mb-2 mb-xl-0">
            <div class="bg-blue p-3 h-100">
              <b-row class="h-100">
                <b-col class="m-auto">
                  <p class="font-weight-bold m-0">จำนวนรายการขายทั้งหมด</p>
                </b-col>
                <b-col
                  class="f-16 font-weight-bold text-white text-right m-auto"
                  >{{ sectionuser.totalOrder | numeral("0,0") }} รายการ</b-col
                >
              </b-row>
            </div>
          </b-col>
        </b-row>

        <b-row class="mx-3 mx-sm-0 my-4">
          <b-col
            xl="6"
            offset-xl="6"
            class="text-right d-flex px-0 justify-content-end"
          >
            <b-input-group class="panel-input-serach">
              <b-form-input
                class="input-serach"
                placeholder="เลขที่รายการ"
                @keyup="handleSearch"
                v-model="filter.search"
              ></b-form-input>
              <b-input-group-prepend @click="btnSearch">
                <span class="icon-input m-auto pr-2">
                  <font-awesome-icon icon="search" title="View" />
                </span>
              </b-input-group-prepend>
            </b-input-group>
            <b-button v-b-toggle.sidebar-1 class="btn-filter">
              <font-awesome-icon
                icon="filter"
                title="filter-btn"
                class="text-white mr-0 mr-sm-1"
              />
              <span class="d-none d-sm-inline"
                >ค้นหา ({{
                  countStatus +
                  countPaymentChannel +
                  countStartdate +
                  countEnddate
                }})</span
              >
            </b-button>
          </b-col>
        </b-row>

        <b-sidebar
          id="sidebar-1"
          title="ค้นหาแบบละเอียด"
          backdrop
          shadow
          backdrop-variant="dark"
          right
          ref="filterSidebar"
        >
          <div class="px-3 py-2">
            <div class="text-right">
              <button
                type="button"
                class="btn btn-link px-0"
                @click="onClearFilter()"
              >
                ล้างค่า
              </button>
            </div>

            <InputSelect
              class="mb-4"
              title="ช่องทางการชำระเงิน"
              name="payment"
              v-bind:options="paymentChannelList"
              valueField="id"
              textField="name"
              v-model="search.paymentChanelSelected"
              @onDataChange="handleChangePaymentChannel"
            />

            <InputSelect
              class="mb-4"
              title="สถานะรายการขาย"
              name="status"
              v-bind:options="statusList"
              valueField="id"
              textField="name"
              v-model="search.statusSelected"
              @onDataChange="handleChangestatus"
            />

            <label class="label-text">วันเริ่มต้น</label>
            <datetime
              placeholder="กรุณาเลือกวันที่"
              class="date-picker"
              format="dd MMM yyyy"
              v-model="filter.StartDate"
            ></datetime>

            <label class="label-text mt-3">วันสิ้นสุด</label>
            <datetime
              placeholder="กรุณาเลือกวันที่"
              class="date-picker"
              format="dd MMM yyyy"
              v-model="filter.EndDate"
            ></datetime>

            <div class="text-center">
              <p class="text-danger" v-if="errorDate">
                วันสิ้นสุดต้องมากกว่าวันเริ่มต้น
              </p>
            </div>

            <div class="text-center mt-4">
              <button
                type="button"
                class="btn btn-purple button"
                @click="getDataByStatus"
              >
                ค้นหา
              </button>
            </div>
          </div>
        </b-sidebar>

        <div class="mt-3 bg-white mx-3 mx-sm-0 pb-3">
          <b-row class="no-gutters">
            <b-col>
              <b-table
                responsive
                class="text-center table-list"
                striped
                :fields="fields"
                :items="items"
                :busy="isBusy"
                show-empty
                empty-text="ไม่พบข้อมูล"
              >
                <template v-slot:cell(orderNo)="data">
                  <router-link :to="'/order/details/' + data.item.id">{{
                    data.item.orderNo
                  }}</router-link>
                </template>
                <template v-slot:cell(paymentType)="data">
                  <p class="mb-0 nobreak" v-if="data.item.paymentType != null">
                    {{ data.item.paymentType }}
                  </p>
                  <p class="m-0" v-else>-</p>
                </template>
                <template v-slot:cell(grandTotal)="data">
                  <p class="m-0">
                    ฿ {{ data.item.grandTotal | numeral("0,0.00") }}
                  </p>
                </template>
                <template v-slot:cell(cashback)="data">
                  <p class="m-0">฿ 0,0</p>
                </template>
                <template v-slot:cell(cashback1)="data">
                  <p class="m-0">-</p>
                </template>
                <template v-slot:cell(createdTime)="data">
                  <span>{{
                    new Date(data.item.createdTime)
                      | moment($formatDateTimeFull)
                  }}</span>
                </template>
                <template v-slot:cell(point)="data">
                  <p class="m-0">{{ data.item.point | numeral("0,0") }}</p>
                </template>
                <template v-slot:cell(qty)="data">
                  <p class="m-0">{{ data.item.qty | numeral("0,0") }}</p>
                </template>
                <template v-slot:cell(orderStatus)="data">
                  <div
                    v-if="
                      data.item.orderStatusId == 10 ||
                      data.item.orderStatusId < 5
                    "
                    class="text-warning"
                  >
                    {{ data.item.orderStatus }}
                  </div>
                  <div
                    v-else-if="
                      data.item.orderStatusId == 5 ||
                      data.item.orderStatusId == 11
                    "
                    class="text-success"
                  >
                    {{ data.item.orderStatus }}
                  </div>
                  <div v-else class="text-danger">
                    {{ data.item.orderStatus }}
                  </div>
                </template>
                <template v-slot:table-busy>
                  <div class="text-center text-black my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Loading...</strong>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row class="no-gutters px-3 px-sm-0">
            <b-col
              class="form-inline justify-content-center justify-content-sm-between"
            >
              <div class="d-sm-flex m-3">
                <b-pagination
                  v-model="filter.pageNo"
                  :total-rows="rows"
                  :per-page="filter.perPage"
                  class="m-md-0"
                  @change="pagination"
                  align="center"
                ></b-pagination>
              </div>

              <b-form-select
                class="mr-sm-3 select-page"
                v-model="filter.perPage"
                @change="hanndleChangePerpage"
                :options="pageOptions"
              ></b-form-select>
            </b-col>
          </b-row>
        </div>
        <!-- <div class="mt-3 bg-white mx-3 mx-sm-0 p-3">
          <h3 class="mr-sm-4 header-main text-uppercase">รายการคะแนนหมดอายุ</h3>
          <b-row class="no-gutters">
            <b-col>
              <b-table
                responsive
                class="text-center table-list"
                striped
                :fields="fieldsPointExpire"
                :items="expiredPoint.dataList"
                :busy="isBusyPointExpire"
                show-empty
                empty-text="ไม่พบข้อมูล"
              >
                <template v-slot:cell(point)="data">
                  <p class="m-0">{{ data.item.point | numeral("0,0") }}</p>
                </template>
                <template v-slot:cell(updatedTime)="data">
                  <span>{{
                    new Date(data.item.updatedTime) | moment($formatDate)
                  }}</span>
                </template>

                <template v-slot:table-busy>
                  <div class="text-center text-black my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Loading...</strong>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row class="no-gutters px-3 px-sm-0">
            <b-col
              class="form-inline justify-content-center justify-content-sm-between"
            >
              <div class="d-sm-flex m-3">
                <b-pagination
                  v-model="expiredPointFilter.pageNo"
                  :total-rows="expiredPoint.count"
                  :per-page="expiredPointFilter.perPage"
                  class="m-md-0"
                  @change="paginationExpiredPoint"
                  align="center"
                ></b-pagination>
              </div>

              <b-form-select
                class="mr-sm-3 select-page"
                v-model="expiredPointFilter.perPage"
                @change="hanndleChangePerpageExpiredPoint"
                :options="pageOptions"
              ></b-form-select>
            </b-col>
          </b-row>
        </div> -->
      </div>
    </div>

    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalLoading ref="modalLoading" :hasClose="false" />
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import InputTextTelephone from "@/components/inputs/InputTextTelephone";
import InputSelect from "@/components/inputs/InputSelect";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import DateTimePicker from "@/components/inputs/DateTimePicker";
import {
  required,
  email,
  minLength,
  maxLength,
  integer,
  minValue,
  requiredIf,
} from "vuelidate/lib/validators";

export default {
  name: "MemberDetails",
  components: {
    InputText,
    InputTextTelephone,
    InputSelect,
    ModalAlertError,
    ModalLoading,
    ModalAlert,
    DateTimePicker,
  },
  data() {
    return {
      id: this.$route.params.id,
      sectionuser: [],
      fields: [
        {
          key: "orderNo",
          label: "เลขที่รายการ",
          class: "w-100px text-nowrap",
        },
        {
          key: "createdTime",
          label: "วันที่ทำรายการ",
          class: "w-100px text-nowrap",
        },
        {
          key: "paymentType",
          label: "ช่องทางการชำระเงิน",
          class: "w-100px text-nowrap",
        },
        { key: "grandTotal", label: "จำนวนเงิน", class: "w-100px text-nowrap" },
        { key: "qty", label: "จำนวนสินค้า", class: "w-100px text-nowrap" },
        {
          key: "orderStatus",
          label: "สถานะคำสั่งซื้อ",
          class: "w-100px text-nowrap",
        },
        // {
        //   key: "point",
        //   label: "คะแนน",
        //   class: "w-100px text-nowrap",
        // },
        // {
        //   key: "cashback1",
        //   label: "รอบบัญชี",
        //   class: "w-100px text-nowrap",
        // },
      ],
      fieldsPointExpire: [
        {
          key: "updatedTime",
          label: "วันหมดอายุ",
        },
        {
          key: "point",
          label: "จำนวนคะแนนที่หมดอายุ",
        },
      ],
      filter: {
        pageNo: 1,
        perPage: 10,
        status: [],
        StartDate: null,
        EndDate: null,
        PaymentChanel: [],
        search: "",
      },
      search: {
        statusSelected: 0,
        paymentChanelSelected: 0,
      },
      pageOptions: [
        { value: 10, text: "10 / หน้า" },
        { value: 30, text: "30 / หน้า" },
        { value: 50, text: "50 / หน้า" },
        { value: 100, text: "100 / หน้า" },
      ],
      paymentChannelFilter: {
        perPage: 10,
        pageNo: 1,
        search: "",
        status: [],
        sortByOrderOrId: 1,
      },
      form: null,
      isBusy: false,
      rows: 0,
      paymentChannelList: [],
      statusList: [],
      items: [],
      modalMessage: "",
      errorDate: false,
      isBusyPointExpire: false,
      expiredPoint: [],
      expiredPointFilter: {
        perPage: 10,
        pageNo: 1,
      },
      isDisable: false,
      list: {
        country: [],
        countryCode: [],
        prefix: [],
      },
    };
  },
  validations() {
    return {
      form: {
        user: {
          firstname: { required },
          lastname: { required },
          email: { required, email },
          birthday: { required },
          prefixId: {
            required,
            minValue: minValue(1),
          },
          prefixDetail: {
            required: requiredIf(function () {
              return this.form.user.prefixId == 4;
            }),
          },
          cardId: {
            required,
            integer,
            maxLength: maxLength(13),
            minLength: minLength(13),
          },
          countryCodeId: { required, minValue: minValue(1) },
          telephone: {
            required,
            minLength: minLength(8),
            maxLength: maxLength(10),
          },
          telephoneExtension: {},
          altNumbers: {
            $each: {
              countryCodeId: { required, minValue: minValue(1) },
              number: {
                required,
                minLength: minLength(8),
                maxLength: maxLength(10),
              },
              extensionNumber: {},
            },
          },
        },
      },
    };
  },

  computed: {
    countPaymentChannel: function () {
      var count = 0;
      if (this.filter.PaymentChanel.length != 0) count += 1;
      else if (count > 0) count -= 1;
      return count;
    },
    countStatus: function () {
      var count = 0;
      if (this.filter.status.length != 0) count += 1;
      else if (count > 0) count -= 1;
      return count;
    },
    countStartdate: function () {
      var count = 0;
      if (this.filter.StartDate != "") count += 1;
      else if (count > 0) count -= 1;
      return count;
    },
    countEnddate: function () {
      var count = 0;

      if (this.filter.EndDate != "") count += 1;
      else if (count > 0) count -= 1;
      return count;
    },
  },
  created: async function () {
    this.getCountry();
    this.getCountryCode();
    this.getPrefix();
    await this.getData();
    await this.getOrder();
    await this.getFilter();
    await this.getSectionUser();
    // await this.getExpiredPoint();
    this.$isLoading = true;
  },
  methods: {
    getExpiredPoint: async function () {
      this.isBusyPointExpire = true;
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/Member/Profile/ExpiredPoint/${this.id}`,
        null,
        this.$headers,
        this.expiredPointFilter
      );
      if (resData.result == 1) {
        this.expiredPoint = resData.detail.result;
      }
      this.isBusyPointExpire = false;
    },
    getSectionUser: async function () {
      let resData = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/Member/SectionProfile/` + this.id,
        null,
        this.$headers,
        null
      );
      if (resData.result == 1) {
        this.sectionuser = resData.detail.result;
      }
    },
    getData: async function () {
      let resData = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/Member/Profile/` + this.id,
        null,
        this.$headers,
        null
      );
      if (resData.result == 1) {
        this.form = resData.detail.result;
        this.form.user.birthday = this.$moment(this.form.user.birthday).format(
          "YYYY-MM-DD"
        );
      }
    },
    getOrder: async function () {
      this.isBusy = true;
      let filter = { ...this.filter };
      if (filter.StartDate) {
        filter.StartDate = this.$moment(filter.StartDate).format(
          "YYYY-MM-DDT00:00:00"
        );
      }
      if (filter.EndDate) {
        filter.EndDate = this.$moment(filter.EndDate)
          .add(1, "day")
          .format("YYYY-MM-DDT00:00:00");
      }
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/Member/Orders/` + this.id,
        null,
        this.$headers,
        filter
      );
      if (resData.result == 1) {
        this.items = resData.detail.dataList;
        this.rows = resData.detail.count;

        this.isBusy = false;
      }
    },
    getFilter: async function () {
      let paymentChannel = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/PaymentChannel/List`,
        null,
        this.$headers,
        this.paymentChannelFilter
      );
      if (paymentChannel.result == 1) {
        let list = [{ id: 0, name: `กรุณาเลือกช่องทางการชำระเงิน` }];
        let datalist = paymentChannel.detail.dataList;
        this.paymentChannelList = list.concat(datalist);
      }

      let status = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/Transaction/OrderStatusWithCount`,
        null,
        this.$headers,
        null
      );

      if (status.result == 1) {
        let list = [];
        let datalist = status.detail;
        this.statusList = list.concat(datalist);
      }
    },
    getCountry: async function () {
      let result = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/Country`,
        null,
        this.$headers
      );
      if (result.result == 1) {
        this.list.country = result.detail;
      }
    },
    getCountryCode: async function () {
      let result = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/Country/Code`,
        null,
        this.$headers
      );
      if (result.result == 1) {
        this.list.countryCode = result.detail;
      }
    },
    getPrefix: async function () {
      let result = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/Prefix`,
        null,
        this.$headers
      );
      if (result.result == 1) {
        let list = [{ id: 0, name: `กรุณาเลือกคำนำหน้า` }];
        this.list.prefix = list.concat(result.detail);
      }
    },
    handleSearch(e) {
      if (e.keyCode === 13) {
        this.filter.pageNo = 1;
        this.getOrder();
      }
    },
    btnSearch() {
      this.filter.pageNo = 1;
      this.getOrder();
    },
    getDataByStatus(status) {
      if (
        this.filter.StartDate > this.filter.EndDate &&
        this.filter.EndDate != ""
      ) {
        this.errorDate = true;
        return;
      }

      this.errorDate = false;
      this.$refs.filterSidebar.hide(true);
      this.getOrder();
    },
    pagination(Page) {
      this.filter.pageNo = Page;
      this.getOrder();
    },
    hanndleChangePerpage(value) {
      this.filter.pageNo = 1;
      this.filter.perPage = value;
      this.getOrder();
    },
    paginationExpiredPoint(Page) {
      this.expiredPointFilter.pageNo = Page;
      this.getExpiredPoint();
    },
    hanndleChangePerpageExpiredPoint(value) {
      this.expiredPointFilter.pageNo = 1;
      this.expiredPointFilter.perPage = value;
      this.getExpiredPoint();
    },
    handleChangePaymentChannel: async function (value) {
      this.filter.PaymentChanel = [];
      this.search.paymentChanelSelected = value;
      if (value != 0) {
        this.filter.PaymentChanel.push(value);
      }
    },
    handleChangestatus: async function (value) {
      this.filter.status = [];
      this.search.statusSelected = value;
      if (value != 0) {
        this.filter.status.push(value);
      }
    },
    onClearFilter() {
      this.filter.pageNo = 1;
      this.filter.StartDate = "";
      this.filter.EndDate = "";
      this.filter.PaymentChanel = [];
      this.filter.status = [];
      this.search.statusSelected = 0;
      this.search.paymentChanelSelected = 0;
      this.errorDate = false;
      this.$refs.filterSidebar.hide(true);
      this.getOrder();
    },
    onChangeEnableMember: async function (value) {
      let body = {
        Enabled: value,
        UserId: this.id,
      };

      let resData = await this.$callApi(
        "patch",
        `${this.$baseUrl}/api/Member/Profile/Enbled`,
        null,
        this.$headers,
        body
      );
      this.modalMessage = resData.message;
      if (resData.result == 1) {
        //this.getData();
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    approveUser: async function (value) {
      this.$refs.modalLoading.show();
      let body = {
        StatusId: value,
        UserId: this.id,
      };

      let resData = await this.$callApi(
        "patch",
        `${this.$baseUrl}/api/Member/Profile/Approve`,
        null,
        this.$headers,
        body
      );
      this.$refs.modalLoading.hide();
      this.modalMessage = resData.message;
      if (resData.result == 1) {
        this.getData();
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    onChangeBirthday: async function (val) {
      this.form.user.birthday = val;
    },
    submit: async function () {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      this.isDisable = true;
      this.$refs.modalLoading.show();

      let data = await this.$callApi(
        "put",
        `${this.$baseUrl}/api/User/${this.id}`,
        null,
        this.$headers,
        this.form.user
      );

      this.modalMessage = data.message;
      this.isDisable = false;
      this.$refs.modalLoading.hide();
      if (data.result == 1) {
        this.modalMessage = "สำเร็จ";
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$refs.modalAlert.hide();
        }, 3000);
        this.getData();
        this.$v.form.$reset();
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    resetPassword: async function () {
      this.isDisable = true;
      this.$refs.modalLoading.show();

      let data = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/ResetPassword/${this.id}`,
        null,
        this.$headers
      );

      this.modalMessage = data.message;
      this.isDisable = false;
      this.$refs.modalLoading.hide();
      if (data.result == 1) {
        this.modalMessage = "สำเร็จ";
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$refs.modalAlert.hide();
        }, 3000);
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    addPhoneNumber() {
      this.form.user.altNumbers.push({ prefixId: 1, number: "", ext: "" });
    },
    deletePhoneNumber(telephone, index) {
      if (index == -1) {
        this.form.user.countryCodeId = 1;
        this.form.user.telephone = "";
        this.form.user.telephoneExtension = "";
        this.$refs.InputTextTelephone.dropdownText = "+66";
      } else {
        this.form.user.altNumbers.splice(index, 1);
      }
    },
    onChangeCountryCodeId: async function (value, index) {
      if (index == -1) {
        this.form.user.countryCodeId = value.id;
      } else {
        this.form.user.altNumbers[index].countryCodeId = value.id;
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    handleChangeTitle: async function (value) {
      this.form.user.prefixId = value;
      if (this.form.user.prefixId !== 4) {
        this.form.user.prefixDetail = "";
      }
    },
  },
};
</script>

<style scoped>
.stars {
  color: #ffb300;
}

.br-10 {
  border-radius: 10px;
}

.bg-blue {
  background-color: #ffb300;
  color: white !important;
  border-radius: 15px;
}

.affiliate-img {
  width: 50%;
  padding-bottom: 50%;
  border-radius: 50%;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  margin: auto;

  /* border-radius: 50%;
    border: 2px solid red; */
}

.seller-status {
  padding: 0 10px;
  border-radius: 10px;
  white-space: nowrap;
}

.status-yellow {
  background-color: #fed378;
}

.status-green {
  background-color: #2eb85c;
}

.status-red {
  background-color: #ff0000;
}
.address-box p {
  margin: 0;
}

.address-box {
  line-height: 25px;
}
.add-phone-number {
  color: #0b59a4;
  cursor: pointer !important;
  font-size: 0.9rem;
  position: absolute;
  right: 0;
  top: 0px;
  z-index: 2;
}
.mt-ext {
  margin-top: 1.5rem;
}
.icon-delete {
  color: #dc3545 !important;
  font-size: 1rem;
  padding: 4px 0;
}
@media (max-width: 1400px) {
  .affiliate-img {
    width: 100%;
    padding-bottom: 100%;
  }
}

@media (max-width: 600px) {
  .affiliate-img {
    width: 50%;
    padding-bottom: 50%;
  }
}
</style>
